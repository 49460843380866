import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse} from '@angular/common/http';
// import { map } from 'rxjs/internal/operators/map';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/internal/operators/tap';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { jwtDecode } from "jwt-decode";
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebservicesService{

  public serviceUrl = environment.serviceUrl;
  SearchForm: FormGroup;
  GetData: any;
  GetHistory:any;
  GetGenereData:any;
  AccessAgeGroup:any;
  // public dataSubject: BehaviorSubject <string> = new BehaviorSubject<string>([]);
  public dataSubject:BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  public toggleDataSubject:BehaviorSubject<string> = new BehaviorSubject<string>("")
  public WatchhistorydataSubject:BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  public GenereSubject:BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  public videoSubject:BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  public DemoVideos:BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  public ProgressBarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public GifSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isToggled = false;
  Token: any;
  token: any;
  currentRoute:any;
  GenreVideos: any = [];
  UserData :any=[];
  constructor(
    public Http: HttpClient,
    private fb: FormBuilder,
    
    private router: Router  ) { 
   
   const Token = localStorage.getItem('accessToken');
    this.SearchForm = this.fb.group({
        serach: [''],
    })
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url; // Get the current route as a string
      }
    });
    
  }
 public decodeToken(token: string): any {
    return jwtDecode(token);
  }

  getprogressbar(): any {
	  return this.ProgressBarSubject.value;
	}
  
	setprogressbar(value: any) {

	  this.ProgressBarSubject.next(value);
	}
  getGif(): any {
	  return this.GifSubject.value;
	}
  
	setGif(value: any) {

	  this.GifSubject.next(value);
	}


  public async logintoAzureWeb() : Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/logintoWebPubSubConnection`;
        return this.Http.get(url)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public Get_genres(): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Genres`;
        return this.Http.get<any>(url)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public GetDemoVideos(): Promise<any> {
    try {
      var AgeGroup = "7-11";
      if(this.AccessAgeGroup!=null && this.AccessAgeGroup!="" && this.AccessAgeGroup!=undefined)
      AgeGroup = this.AccessAgeGroup;
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/DemoVideos?AgeGroup=${AgeGroup}`;
        return this.Http.get<any>(url)
        .pipe(
          // tap(d => console.log(d)),
          map(d => (d) ? d : false))
        .subscribe((r: any) => resolve(r), reject);
    }).catch(err => console.log(err));
  } catch (error) {
    console.log(error);
  }
}

  Get_Genre_Videos() {
    const url = `${this.serviceUrl}/Discover`;
    return this.Http.get<any>(url).pipe(
      // tap(d => console.log(d)),
      map(d => (d.Status_cd !== "0") ? d : 'failed')
    )
  }

  public Getadvertsements() {
    const url = `${this.serviceUrl}/Advertisement`;
    return this.Http.get<any>(url).pipe(
      map(d => (d.Status_cd !== "0") ? d : 'failed')
    )
  }

  public async GetAgeGroupVideos(body): Promise<any> {
    
    try {
      
        if(this.AccessAgeGroup!=null && this.AccessAgeGroup!="" && this.AccessAgeGroup!=undefined)
          body.AgeGroup = this.AccessAgeGroup;
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Discover`;
        return this.Http.post<any>(url, body)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public async RefreshGenereVideos(body): Promise<any> {
    
    try {
      
        if(this.AccessAgeGroup!=null && this.AccessAgeGroup!="" && this.AccessAgeGroup!=undefined)
          body.AgeGroup = this.AccessAgeGroup;
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Discover`;
        return this.Http.post<any>(url, body)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public async GetAgeGroupVideosOfSubGenres(body): Promise<any> {
    try {
      if(this.AccessAgeGroup!=null && this.AccessAgeGroup!="" && this.AccessAgeGroup!=undefined)
      body.AgeGroup = this.AccessAgeGroup;
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/GetSubGenreVideos`;
        return this.Http.post<any>(url, body)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  public async Genres(): Promise<any> {
    //
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Genres?role=user`;
        // console.log(url)
        return this.Http.get<any>(url)
          .pipe(
            // tap(d => console.log(d)),
            // map(d => (d.Status_cd !== "0") ? d : 'failed'))
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);

      }).catch(err => console.log(err));
    } catch (error) {
      
      console.log(error);
    }
  }
   
  setData(data){
    
    // console.log(data);
    this.GetData=data;
    this.dataSubject.next(data);
    // console.log(this.getData());
  }

  getData():BehaviorSubject<any[]>{
    return this.dataSubject;
  }
  setSpecificData(data){
    // console.log(data);
    this.GetGenereData=data;
    this.GenereSubject.next(data);
    // console.log(this.getData());
  }

  getSpecificData():BehaviorSubject<any[]>{
    return this.GenereSubject;
  }
  sethistory(data){
    
    // console.log(data);
    this.GetHistory=data;
    this.WatchhistorydataSubject.next(data);
    // console.log(this.getData());
  }

  gethistory():BehaviorSubject<any[]>{
   
    return this.WatchhistorydataSubject;
  }
  setvideo(data){
    this.videoSubject.next(data);
    // console.log(this.getData());
  }

  getvideo():BehaviorSubject<any[]>{
   
    return this.videoSubject;
  }
  setDemoData(data){
    // console.log(data);
    //this.GetData=data;
    this.dataSubject.next(data);
    // console.log(this.getData());
  }

  getDemoData():BehaviorSubject<any[]>{
    return this.dataSubject;
  }
  get isAgeToggled(): any {
	  return this.toggleDataSubject.value;
	}
  
	setToggle(value: any) {
    this.AccessAgeGroup= value;
    localStorage.setItem("ToggledAge",value);
	  this.toggleDataSubject.next(value);
	}
	// GetSearchVideoss(){
	// 	let body = {
	// 	  "AgeGroup" : "12-16",
	// 	  "Genres":"",
	// 	  "SearchByText":"",
	// 	  "pageIndex":""
	// 	}
	// 	this.GetAgeGroupVideos(body).then((r :any) =>{
	// 	  this.GetData = r;
	// 	  console.log(this.GetData);
	// 	})
	// }

  public async Login(): Promise<any> {
    try {
      // const headers = new HttpHeaders({
      //   Authorization: 'bearer ' + this.Token
      // });
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/userflowurl?userrole=user`;
        // console.log(url)
        return this.Http.get<any>(url)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  


  public async EditUserDetails(body): Promise<any> {
    try {
      // const headers = new HttpHeaders({
      //   Authorization: 'bearer ' + this.Token
      // });
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/userflowurl`;
        // console.log(url)
        return this.Http.put<any>(url, body)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public async ForgetPassword(body): Promise<any> {
    try {
      // const headers = new HttpHeaders({
      //   Authorization: 'bearer ' + this.Token
      // });
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/userflowurl`;
        // console.log(url)
        return this.Http.post<any>(url, body)
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public  GeTProfileDetails() {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('No access token found');
      }
  
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + token
      });
  
      const url = `${this.serviceUrl}/UserProfile`;
  
      return  this.Http.get<any>(url, { headers }).pipe(
        map(d => (d.Status_cd !== "0") ? d : 'failed'),
        catchError(async error => {
          console.error('An error occurred while fetching profile details:', error);
          return await this.handleLogoutAfterSubExp();
        })
      );
    } catch (error) {
      console.error('An error occurred in getProfileDetails:', error);
      return throwError(() => new Error('An error occurred while fetching profile details'));
    }
  }
  
  private async handleLogoutAfterSubExp() {
    try {
      
      const currentDeviceId = localStorage.getItem("CurrentDeviceId");
      const token = localStorage.getItem('accessToken');
  
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + token
      });
  
      const url = `${this.serviceUrl}/UserSignOut?Id=${currentDeviceId}`;
  
      const response = await this.Http.get<any>(url, { headers }).toPromise();
  
      if (response && response.Value) {
        // Perform logout actions
        localStorage.removeItem('accessToken');
        localStorage.clear();
        await this.router.navigateByUrl("/on-boarding");
        window.location.reload();
      } else {
        console.error('Logout failed or response.Value is missing');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
      localStorage.removeItem('accessToken');
      localStorage.clear();
      await this.router.navigateByUrl("/on-boarding");
      window.location.reload();
    }
  }

  public async GeTSubscriptionDetails(): Promise<any> {
    try {

      const token = localStorage.getItem('accessToken');
      // console.log(token)
      
      const headers = new HttpHeaders({
        Authorization: 'bearer ' + token
      });
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Subscriptionplans`;
        // console.log(url)
        return this.Http.get<any>(url,{headers})
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public async GeTSubscriptionDetailsById(Id): Promise<any> {
    
    try {

      const token = localStorage.getItem('accessToken');
      const headers = new HttpHeaders({
        Authorization: 'bearer ' + token
      });
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Subscriptionplans?Id=${Id}`;
        return this.Http.get<any>(url,{headers})
          .pipe(
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public async GetUserSubscriptionDetails(): Promise<any> {
    
    try {

      const token = localStorage.getItem('accessToken');
      // console.log(token)
      
      const headers = new HttpHeaders({
        Authorization: 'bearer ' + token
      });
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/UserSubscription`;
        // console.log(url)
        return this.Http.get<any>(url,{headers})
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public async GetCouponCodes(): Promise<any> {
    
    try {
      
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Coupons`;
        return this.Http.get<any>(url)
          .pipe(
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
  public async ApplyCoupon(SubscriptionId,CouponCode): Promise<any> {
    
    try {
      const token = localStorage.getItem('accessToken');
      // console.log(token)
      
      const headers = new HttpHeaders({
        Authorization: 'bearer ' + token
      });
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/ApplyCoupon?SubscriptionId=${SubscriptionId}&Code=${CouponCode}`;
        return this.Http.get<any>(url,{headers})
          .pipe(
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  public  GeTWatchHistoryDetails(search) {
    try {
   this.UserData=JSON.parse(localStorage.getItem("UserData"));
      const token = localStorage.getItem('accessToken');
      var AgeGroup = "7-11";
      if(this.AccessAgeGroup!=null && this.AccessAgeGroup!="" && this.AccessAgeGroup!=undefined)
          AgeGroup = this.AccessAgeGroup;
      else if(this.UserData?.AgeGroup)
          AgeGroup=this.UserData?.AgeGroup;
      // console.log(token)
      const headers = new HttpHeaders({
        Authorization: 'bearer ' + token
      });
        const url = `${this.serviceUrl}/WatchHistory?AgeGroup=${AgeGroup}&SearchByText=${search}`;
        // console.log(url)
        return this.Http.get<any>(url,{headers})
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
            return this.Http.get<any>(url,{headers}).pipe(
              map(d => (d.Status_cd !== "0") ? d : 'failed')
            )
            catchError(async error => {
              console.error('An error occurred while fetching profile details:', error);
              return await this.handleLogoutAfterSubExp();
            })
    } catch (error) {
      console.log(error);
    }
  }
  public async GetGeoIp(): Promise<any> {
   
    return new Promise((resolve, reject) => {
      const url ='https://ipapi.co/json';
      return this.Http.get(url, )
        .pipe(
           tap(d => console.log(d)),
          map(d => (d) ? d : false))
        .subscribe((r: any) => resolve(r), reject);
    }).catch(err => console.log(err));
  }
  public async GeTUserRoles(): Promise<any> {
    try {
      const token = localStorage.getItem('accessToken');
      // console.log(token);
      var formData: any = new FormData();
      formData.append('code', localStorage.getItem("code"));
       const headers = new HttpHeaders({
        Authorization: 'bearer ' + token
       });
       
      //  console.log(headers);
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/userRole?userrole=user`;
        // console.log(url)

        return this.Http.post(url, formData,{headers} )
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err =>this.handleLogoutAfterSubExp());
    } catch (error) {
      console.log(error);
    }
  }
	
	Roles() {

		const urlFragment = window.location.hash;
		const token = urlFragment?.split('=')[1];
		this.token= this.Token;
    // console.log(this.token);

		// 

		if (localStorage.getItem('accessToken')) {

		  // Store token in localStorage
		  // localStorage.removeItem('accessToken');
		  // Remove token from URL

		  const newUrl = window.location.href.split('#')[0];
      // console.log(newUrl);
		  window.history.replaceState({}, document.title, newUrl)
		  // console.log(this.Token);

		  // localStorage.setItem('accessToken', this.token);
			//

			this.GeTUserRoles().then(r =>{
        if(r.Value){
          
					if(r.Value){
            if(r.Value.IdToken!="")
              localStorage.setItem("accessToken",r.Value.IdToken);
  
            if(r.Value.rolemapped && !r.Value.IsDeviceLimitExceed){
  
            }
            else if(r.Value.IsDeviceLimitExceed){
  
            }
          }
				}
        //
        // alert('hi');
				// console.log(r);
        // alert('webts - 270');
			});
		}
		// this.API.Get_genres().then((r) => {
		// //   this.Genress = r.Value;
		//   // this.UserDetails();
		// })
	  }


    public async DeleteHistoryvideo(id): Promise<any> {
      try {
        const token = localStorage.getItem('accessToken');
        // console.log(token);
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
        //  console.log(headers);

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/WatchHistory?Id=${id}`;
          // console.log(url)
          return this.Http.delete<any>(url, {headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public async DeleteAllVideoHistorybyAge(id): Promise<any> {
      try {
        const token = localStorage.getItem('accessToken');
        // console.log(token);
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
        //  console.log(headers);

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/WatchHistory?Id=${id}`;
          // console.log(url)
          return this.Http.delete<any>(url, {headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public async DeleteSelectedWatchHistory(SelectedIds): Promise<any> {
      
      try {
        const token = localStorage.getItem('accessToken');
        // console.log(token);
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
       

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/WatchHistory`;
          // console.log(url)
          return this.Http.post<any>(url,SelectedIds ,{headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }


    public async DeleteAllHistory(): Promise<any> {
      try {

        const token = localStorage.getItem('accessToken');
        // console.log(token);
  
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
        //  console.log(headers);

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/WatchHistory`;
          // console.log(url)
          return this.Http.delete<any>(url, {headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public async Logout(): Promise<any> {
      
      try {
        var CurrentDeviceId = localStorage.getItem("CurrentDeviceId");
        const token = localStorage.getItem('accessToken');
        // console.log(token);
  
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
        //  console.log(headers);

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/UserSignOut?Id=${CurrentDeviceId}`;
          // console.log(url)
          return this.Http.get<any>(url, {headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public async LogoutAfterSubExp() {
      try {
        const CurrentDeviceId = localStorage.getItem("CurrentDeviceId");
        const token = localStorage.getItem('accessToken');
    
        const headers = new HttpHeaders({
          Authorization: 'Bearer ' + token
        });
    
        const url = `${this.serviceUrl}/UserSignOut?Id=${CurrentDeviceId}`;
    
        const response = await this.Http.get<any>(url, { headers }).pipe(
          map(d => d ? d : false),
        ).toPromise();
    
        if (response && response.Value) {
          
          // Perform logout actions
          localStorage.removeItem('accessToken');
          localStorage.clear();
          await this.router.navigateByUrl("/on-boarding");
          window.location.reload();
        } else {
          // Handle the case where response.Value is not present
          console.error('Logout failed or response.Value is missing');
        }
      } catch (error) {
        console.error('An error occurred during logout:', error);
      }
    }
    // https://ottappfunctions.azurewebsites.net/api/UserSubscription?Id=40394763749834 

    public async Subscription(id): Promise<any> {
      try {

        const token = localStorage.getItem('accessToken');
        console.log(token);
  
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
         console.log(headers);

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/UserSubscription?Id=${id}`;
          console.log(url)
          return this.Http.post<any>(url, {Response:'text'} ,{headers})
            .pipe(
              tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => this.handleLogoutAfterSubExp());
      } catch (error) {
        console.log(error);
      }
    }
    public  CreatePaymentandGetOrderDetails(id,Code): Promise<any> {
      try {
    
        const token = localStorage.getItem('accessToken');
        console.log(token);
  
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
         console.log(headers);

        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/RazorPayPayment?SubscriptionId=${id}&Code=${Code}`;
          console.log(url)
          return this.Http.get<any>(url, {headers})
            .pipe(
              tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public async verifyPayment(body): Promise<any> {
      try {

        const token = localStorage.getItem('accessToken');
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/RazorPayPayment`;
          console.log(url)
          return this.Http.post<any>(url, body ,{headers})
            .pipe(
              tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public GetAllFAQ() {
      const url = `${this.serviceUrl}/FrequentlyAskedQuestions`;
      return this.Http.get<any>(url).pipe(
        map(d => (d.Status_cd !== "0") ? d : 'failed')
      )
    }
    public GetTermsandConditions() {
      const url = `${this.serviceUrl}/TermsAndConditions`;
      return this.Http.get<any>(url).pipe(
        map(d => (d.Status_cd !== "0") ? d : 'failed')
      )
    }
    public GetPrivacyPolicies() {
      const url = `${this.serviceUrl}/Privacypolicy`;
      return this.Http.get<any>(url).pipe(
        map(d => (d.Status_cd !== "0") ? d : 'failed')
      )
    }
    public GetActiveDeviceDetails() {
      try {

        const token = localStorage.getItem('accessToken');
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         const url = `${this.serviceUrl}/UserDeviceInfo`;
         return this.Http.get<any>(url,{headers}).pipe(
          map(d => (d.Status_cd !== "0") ? d : 'failed')
        )
      } catch (error) {
        console.log(error);
      }
    }
    public GetDeviceStatus(id): Promise<any> {
      try {

        const token = localStorage.getItem('accessToken');
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/UserDeviceInfo?Id=${id}`;
          return this.Http.get<any>(url, {headers})
            .pipe(
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    public DeActiveDeviceDetails(deviceId) {
      try {
        const token = localStorage.getItem('accessToken');
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         const url = `${this.serviceUrl}/UserDeviceInfo?Id=${deviceId}`;
         return this.Http.delete<any>(url,{headers}).pipe(
          map(d => (d.Status_cd !== "0") ? d : 'failed')
        )
      } catch (error) {
        console.log(error);
      }
    }
    public AddDeviceInfo(body): Promise<any> {
      
      try {
        const token = localStorage.getItem('accessToken');
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/UserDeviceInfo`;
          return this.Http.post(url, body,{headers} )
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => this.handleLogoutAfterSubExp());
    } catch (error) {
      console.log(error);
    }
    }
    public CheckStatusAndAddDeviceInfo(body): Promise<any> {
      
      try {
        const token = localStorage.getItem('accessToken');
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/UserDeviceInfo?Id=0`;
          return this.Http.post(url, body,{headers} )
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => this.handleLogoutAfterSubExp());
    } catch (error) {
      console.log(error);
    }
    }
    public async Streamvideo(StramId, CurrentDeviceId): Promise<any> {
      
      const token = localStorage.getItem('accessToken');
      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
        });
    
        const url = `${this.serviceUrl}/StreamingData?Id=${StramId}&deviceId=${CurrentDeviceId}`;
        return this.Http.get<any>(url, { headers })
          .pipe(
            // tap(d => console.log(d)),
            map(d => (d) ? d : false)
          )
          .subscribe(
            (r: any) => {
              if (r !== false) {
                resolve(r);
              } else {
                reject('Backend response is false or undefined.');
              }
            },
            (error) => reject(error)
          );
      }).catch(err => this.handleLogoutAfterSubExp());
    }
    public async DeleteUserAccount(): Promise<any> {
      
      try {
        const token = localStorage.getItem('accessToken');
        // console.log(token);
         const headers = new HttpHeaders({
          Authorization: 'bearer ' + token
         });
         
        //  console.log(headers);
  
        return new Promise((resolve, reject) => {
          const url = `${this.serviceUrl}/DeleteUser`;
          // console.log(url)
          return this.Http.delete<any>(url, {headers})
            .pipe(
              // tap(d => console.log(d)),
              map(d => (d) ? d : false))
            .subscribe((r: any) => resolve(r), reject);
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
}
