<footer>
    <div class="footer-top-wrap d-none d-md-block">
        <div class="container">
            <!-- <div class="footer-menu-wrap">
                <div class="row align-items-center">
                    <div class="col-lg-3">
                        <div class="footer-logo">
                            <a [routerLink]="['/home']"><img src="assets/img/logo/PexcelLogo.png" alt=""></a>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="footer-menu">
                            <nav>
                                <ul class="navigation">
                                    <li><a [routerLink]="['/home']">Home</a></li>
                                    <li><a [routerLink]="['/generes']">Genres</a></li>
                                </ul>
                                <div class="footer-search">
                                    <form action="#">
                                        <input type="text" placeholder="Search">
                                        <button (click)="searchtext()"><i
                                            class="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </nav> 
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="footer-quick-link-wrap ">
                <div class="row align-items-center">
                    <div class="col-md-10">
                        <div class="quick-link-list">
                            <ul>
                                <li><a target="_blank" href='https://play.google.com/store/apps/details?id=com.pexcel.app'><img style="border-radius: 30px;" src="assets/img/logo/playstore.png" alt="Mobile Logo"></a></li>
                                <li><a [routerLink]="['/about']">About Us</a></li>
                                <li><a [routerLink]="['/contact']">Contact US </a></li>
                                <li><a [routerLink]="['/privacy-policy']"> Privacy Policy </a></li>
                                <li><a [routerLink]="['/terms-conditions']"> Terms & Conditions </a></li>
                                <li><a [routerLink]="['/refunds']"> Refunds/Cancellations </a></li>
                                <!-- <li><a [routerLink]="['/terms-conditions']">Terms & Conditions</a></li>
                                <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="col-md-5">
                        <div class="footer-social">
                            <ul>
                                <li><a [routerLink]="['/home']"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a [routerLink]="['/home']"><i class="fab fa-twitter"></i></a></li>
                                <li><a [routerLink]="['/home']"><i class="fab fa-pinterest-p"></i></a></li>
                                <li><a [routerLink]="['/home']"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright-text text-center">
                        <p>Copyrights &copy; 2023. All Rights Reserved By <a [routerLink]="['/home']">Prasaram Learning Initiatives</a></p>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-6">
                    <div class="payment-method-img text-center text-md-right">
                        <img src="assets/img/images/card_img.png" alt="img">
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</footer>