import { Component, OnInit } from '@angular/core';
import { WebservicesService } from 'src/services/webapis';


@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {
  showGif:boolean=true;
  constructor(
		public API: WebservicesService,
  ) { 
    
  }

  ngOnInit(): void {
    this.API.GifSubject.subscribe((value:any)=>{
      this.showGif= value
   });
  
  }

}
