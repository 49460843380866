// spinner.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private _showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSpinner$ = this._showSpinner.asObservable();
  private isLoggedIn: boolean = false;

  get showSpinner(): boolean {
    return this._showSpinner.value;
  }

  show() {
    this._showSpinner.next(true);
  }

  hide() {
    this._showSpinner.next(false);
  }
  getIsLoggedIn(): boolean {
    
    return this.isLoggedIn;
  }

  setIsLoggedIn(value: boolean): void {
    
    this.isLoggedIn = value;
  }
}
