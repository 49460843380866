import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WebservicesService } from 'src/services/webapis';
import { SpinnerService } from 'src/app/spinner.service';


@Component({
  selector: 'com-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	showFooter: boolean = false;

  isLoggedIn :boolean= false;
  constructor(
	private fb: FormBuilder,
	private SpinnerService: SpinnerService,

    public API: WebservicesService)
    {
		
		this.isLoggedIn = false;
		this.SpinnerService.setIsLoggedIn(false);
	}

  ngOnInit(): void {
    this.API.GifSubject.subscribe((value:any)=>{
      
      this.isLoggedIn= this.SpinnerService.getIsLoggedIn();
   });
  }
  // ngAfterViewInit(){
	// 
	// this.isLoggedIn= this.SpinnerService.getIsLoggedIn();
  // }
}
