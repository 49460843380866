import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BodyComponent } from './body/body.component';
import { AuthGuard } from 'src/app/auth.guard';
const routes: Routes = [
  {
    path: '',
    component: BodyComponent,
    children: [
      // {
      //   path: '',
      //   loadChildren: () =>
      //     import('./pages/sign-in/sign-in.module').then((m) => m.SignInModule), canActivate: [AuthGuard]
      // },
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: 'generes', loadChildren: () => import('./pages/movies/movies.module').then(m => m.MoviesModule) },
      { path: 'movie-details', loadChildren: () => import('./pages/movie-details/movie-details.module').then(m => m.MovieDetailsModule) },
      { path: 'tv-shows', loadChildren: () => import('./pages/tv-shows/tv-shows.module').then(m => m.TvShowsModule) },
      { path: 'price-plans', loadChildren: () => import('./pages/price-plans/price-plans.module').then(m => m.PricePlansModule) },
      { path: 'blogs', loadChildren: () => import('./pages/blogs/blogs.module').then(m => m.BlogsModule) },
      { path: 'DeleteAccount', loadChildren: () => import('./pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule) },
      { path: 'contact', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
      { path: 'on-boarding', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule) },
      { path: 'sign-up', loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule) },
      { path: 'forget-pwd', loadChildren: () => import('./pages/forget-pwd/forget-pwd.module').then(m => m.ForgetPwdModule) },
      { path: 'my-profile', loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule) },
      { path: 'my-downloads', loadChildren: () => import('./pages/my-downloads/my-downloads.module').then(m => m.MyDownloadsModule) },
      { path: 'watch-history', loadChildren: () => import('./pages/watch-history/watch-history.module').then(m => m.WatchHistoryModule) },
      { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
      { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
      { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
      { path: 'otp-verification', loadChildren: () => import('./pages/mobile-otp-verification/mobile-otp-verification.module').then(m => m.MobileOtpVerificationModule) },
      { path: 'payment/:id', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule) },
      { path: 'Particular_genre', loadChildren: () => import('./pages/genre-videos/genre-videos.module').then(m => m.GenreVideosModule) },
      { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
      // { path: 'play_video/:id/:name', loadChildren: () => import('./pages/play-video/play-video.module').then(m => m.PlayVideoModule) },
      { path: 'play_video/:id', loadChildren: () => import('./pages/play-video/play-video.module').then(m => m.PlayVideoModule) },
      { path: 'device-detail', loadChildren: () => import('./pages/device-detail/device-detail.module').then(m => m.DeviceDetailModule) },
      { path: 'refunds', loadChildren: () => import('./pages/refunds/refunds.module').then(m => m.RefundsModule) }

    ]
  },

  //{ path: 'genre-details', loadChildren: () => import('./pages/genre-details/genre-details.module').then(m => m.GenreDetailsModule) },
  { path: '**', redirectTo: '' },
  //{ path: 'privacy-policy', redirectTo: 'privacy-policy' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
