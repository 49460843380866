

<!-- preloader -->
<div *ngIf="showProgressBar">
    <mat-progress-bar mode="indeterminate" color="warn" ></mat-progress-bar></div>
<!-- Scroll-top -->
<!-- <button class="scroll-top scroll-to-target" data-target="html">
    <i class="fas fa-angle-up"></i>
</button> -->
<!-- Scroll-top-end-->

<!--=====================================-->
<!--=            Header Start           =-->
<!--=====================================-->
<header class="header-style-two">
    <div id="mobile-only-div" *ngIf="showPopup">
        <div class="download-app-dialog">
            <p>Download our app for a better experience</p>
            <a style="cursor: pointer;" target="_blank" href="https://play.google.com/store/apps/details?id=com.pexcel.app">
                <img class="img-fluid" style="border-radius: 30px;" src="assets/img/logo/playstore.png" alt="Mobile Logo">
              </a>
            <button (click)="closeDialog()">&times;</button>
          </div>
          
    </div>
    <!-- <div *ngIf="!tokenname" class="headeronboard" style="text-align: center;">
        <img src="assets/img/logo/app_logo(1).png" alt="Netflix Logo" class="logoMain">
        <button  [routerLink]="['/privacy-policy']" class="sign-in">Privacy Policy</button>
        <button (click)="clicksignIn($event)" class="sign-in">Login / Register</button>
      </div>
       -->
      
    <div *ngIf="tokenname" id="sticky-header" class="menu-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="menu-wrap">
                        <!-- Desktop View - Start -->
                        <div class="WebView">
                            <nav class="navbar navbar-expand-lg navbar-light">

                                <div>
                                    <a [routerLink]="['/home']">
                                        <!-- <------------ rLOGO DESKTOP---------------> 
                                       <img src="assets/img/logo/PexcelLogo.png" alt="Logo" class="nav_img"> 
                                    </a>
                                </div>
                                <div class="navbar-wrap d-lg-flex header-action">
                                    <ul class="navigation">
                                        <li><a [routerLink]="['/home']">Home</a> </li>
                                        <li><a  [routerLink]="['/generes']">Genres</a></li>
                                        <li><a *ngIf="!IsSubscribed" [routerLink]="['/price-plans']">Subscribe</a></li>
                                    </ul>
                                </div>
                                <div class="header-action  d-md-block serch_width mt_12 headercontentwidth">
                                    <ul>
                                        <!-- <-------- not mobile ----------------> 
                                        <li  class="header-btn desktop-only d-none d-md-block header-btn">
                                            <label class="switch" style="width: 81px; text-align: center;">
                                                <input (click)="clickEvent($event)" [class.active]="isToggled"
                                                    class="switch-input" type="checkbox"/>
                                                <span id="toggleageswicth" class="switch-label" data-on="12-16 Yr" data-off="7-11 Yr"
                                                    style="background: #ed6834;"></span>
                                                <span class="switch-handle"></span>
                                               <div class="divswitch"><span class="spanswitchage" style="color:#bcbcbc"><b>Switch Age</b></span></div>
                                            </label>
                                        </li>
                                       
                                        <li class="header-btn header-search- d-none d-md-block">
                                            <div class="footer-search">
                                                <form [formGroup]="SearchForm" class="mt-0">
                                                    <div class="form-group" style="margin-top: 17px;">
                                                        <input formControlName="serach" type="text"
                                                            placeholder="Search Video">
                                                        <button (click)="searchtext()"><i
                                                                class="fas fa-search"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </li> 
                                        <li class="menu-item-has-children-"><b class="displynamesize" style="font-size: 14px;color: white;">{{FullName}}</b></li>
                                        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
                                            <div id="liveToast" class="toast hide" role="alert" aria-live="assertive"
                                                aria-atomic="true">
                                                <div class="toast-header" style="display: flex; align-items: center; justify-content: space-between">
                                                    <img alt="assets/img/logo/PexcelLogo.png" class="me-auto captionn" style="width: 80px; height: 80px;" [src]="NotificationImage">
                                                    <div style="text-align: center; font-weight: bold; color: #ffffff;">{{NotificationTitle}}</div>
                                                    <button aria-label="Close" class="btn-close" (click)="CloseNotification()" data-bs-dismiss="toast" type="button"></button>
                                                </div>
                                                <div class="toast-body">
                                                   {{NotiticationDescription}}
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                <div class="navbar-wrap d-none  d-lg-flex d-sm-flex" style="flex-grow: 0;margin: -44px">
                                    <ul class="navigation">
                                        <li class="header-btn2"><a class="btn accnt"> <i class="fa fa-user"></i>
                                                 </a>
                                            <ul class="submenu">
                                                <li *ngIf="!tokenname" class="active menu-item-has-children-"><a (click)="clicksignIn($event)"  style="cursor: pointer">Login</a></li>
                                                  <!-- <li><a><b class="displynamesize" style="
                                                  font-size: 14px;color: white;">{{FullName}}</b></a></li> -->
                                                <li><a *ngIf="IsSubscribed" [routerLink]="['/price-plans']">Subscription Details</a> </li>
                                                <li><a *ngIf="!IsSubscribed" [routerLink]="['/price-plans']">Subscribe</a></li>
                                                <li class="active" *ngIf="tokenname"><a [routerLink]="['/my-profile']">My  Account</a></li>
                                                <li><a *ngIf="tokenname" [routerLink]="['/device-detail']">Active Devices </a></li>
                                                <li *ngIf="tokenname"><a [routerLink]="['/watch-history']"> Watch History </a></li>
                                                <li><a [routerLink]="['/faq']"> Help & Faq's </a></li>
                                                <li><a [routerLink]="['/privacy-policy']"> Privacy Policy </a></li>
                                                <li><a [routerLink]="['/terms-conditions']"> Terms & Conditions </a></li>
                                                <li><a [routerLink]="['/refunds']"> Refunds/Cancellations </a></li>
                                                <li><a [routerLink]="['/contact']">Contact US </a></li>
                                                <li><a [routerLink]="['/about']">About Us</a></li>
                                                <li class="active" *ngIf="tokenname" (click)="logout()"><a style="cursor: pointer;">Logout</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        
                    <!-- Desktop View - End -->

                    <!-- Mobile View - Start -->
                    <div class="MblView">
                        <nav class="navbar navbar-light">
                                <div class="logo-container">
                                   
                                        <a [routerLink]="['/home']">
                                            <img width="35px" height="50px" style="margin-top: -16px;" src="assets/img/logo/pexcel_logo_p.png" alt="Mobile Logo">
                                        </a>
                                        <div class="home-text" ><a [routerLink]="['/home']" style="color: white;">Home</a></div>
                                    
                                </div>
                                <div class="logo-container">
                                  <div style="text-align:center;">
                                        <a [routerLink]="['/generes']" class="menu-icon"><img width="30px" height="33px" src="assets/img/logo/list-view.png" alt=""></a>
                                    </div>
                                        <div class="home-text">
                                            <a style="color: white;" [routerLink]="['/generes']">Genres</a>
                                        </div>
                                    
                                </div> 
                                <div class="logo-container">
                                    <li  class="header-btn">
                                        <label class="switch" style="width: 81px; text-align: center;">
                                            <input (click)="clickEvent1($event)" [class.active]="isToggled"
                                                class="switch-input" type="checkbox"/>
                                            <span id="toggleageswicth1" class="switch-label" data-on="12-16 Yr" data-off="7-11 Yr"
                                                style="background: #ed6834;"></span>
                                            <span class="switch-handle"></span>
                                        </label>
                                        <span class="spanswitchage" style="color:#ffffff;margin-left: 12px;"><b>Switch Age</b></span>

                                    </li>
                                </div>
                                <div class="logo-container">
                                    <li class="header-btn header-search-">
                                        <div class="footer-search">
                                            <div style="text-align:center;">
                                                <a (click)="toggleSearch()" class="search-icon"> <img width="30px" height="30px" src="assets/img/logo/search.png" alt=""></a>
                                            </div>
                                            <div class="home-text" style="margin-top: 11px;">
                                                <a [routerLink]="['/home']" style="color: white;margin-left: 4px;">Find</a>
                                            </div>
                                            <div id="search-container" style="display: none;">
                                                <i class="fas fa-search search-icon1"(click)="mblsearchtext()"></i>  
                                                <input type="text" id="searchInput" class="search-input" placeholder="Search Video">
                                               
                                                <i class="fas fa-window-close close-icon" (click)="toggleSearch()"></i>
                                           </div>
                                              
                                        </div>
                                    </li>
                                </div>
                                
                                <div class="logo-container">
                                    <li class="mobile-nav-toggler"><i class="fas fa-user mbl-MyAcc"></i>
                                        <!-- <div class="home-text" style="margin-top: 2px !important;">
                                            <a> Account</a>
                                        </div> -->
                                    </li>
                                    <span class="spanswitchage" style="color:#ffffff"><b>Account</b></span>

                                </div>
                        </nav>
                    </div>
                    <!-- Mobile View - End -->
                    <div class="mobile-menu" id="MobileMenu">
                        <div class="close-btn"><i class="fas fa-times"></i></div>
                        <nav class="menu-box">
                            <div class="nav-logo"><a [routerLink]="['/home']"><img src="assets/img/logo/PexcelLogo.png" alt=""
                                        title=""></a>
                            </div>
                            <ul class="navigation">
                                <li class="header-btn2"><a class="btn accnt"> <i class="fa fa-user"></i>
                                         </a>
                                    <ul class="submenu">
                                        <li *ngIf="!tokenname"><a id="loginclick" (click)="clicksignIn1($event)">Login</a></li>
                                          <li *ngIf='FullName!=""'><a><b class="displynamesize" style="
                                          font-size: 13px;color: #ed6834;;">{{FullName}}</b></a></li>
                                        <li><a *ngIf="IsSubscribed" [routerLink]="['/price-plans']"  (click)="closeMenuOnNavigation()">Subscription Details</a> </li>
                                        <li><a *ngIf="!IsSubscribed" [routerLink]="['/price-plans']"  (click)="closeMenuOnNavigation()">Subscribe</a></li>
                                        <li (click)="closeMenuOnNavigation()" *ngIf="tokenname"><a [routerLink]="['/my-profile']">My  Account</a></li>
                                        <li><a *ngIf="tokenname" (click)="closeMenuOnNavigation()" [routerLink]="['/device-detail']">Active Devices </a></li>
                                        <li *ngIf="tokenname"><a (click)="closeMenuOnNavigation()" [routerLink]="['/watch-history']"> Watch History </a></li>
                                        <li><a (click)="closeMenuOnNavigation()" [routerLink]="['/faq']"> Help & Faq's </a    ></li>
                                        <li><a (click)="closeMenuOnNavigation()" [routerLink]="['/privacy-policy']"> Privacy Policy </a></li>
                                        <li><a (click)="closeMenuOnNavigation()" [routerLink]="['/terms-conditions']"> Terms & Conditions </a></li>
                                        <li><a (click)="closeMenuOnNavigation()" [routerLink]="['/refunds']"> Refunds/Cancellations </a></li>
                                        <li><a (click)="closeMenuOnNavigation()" (click)="closeMenuOnNavigation()" [routerLink]="['/contact']">Contact US </a></li>
                                        <li><a (click)="closeMenuOnNavigation()"  [routerLink]="['/about']">About Us</a></li>
                                        <li *ngIf="tokenname" (click)="logout()"><a style="cursor: pointer;">Logout</a></li>
                                    </ul>
                                </li>
                            </ul>
                            <div class="menu-outer"> </div>
                        </nav>
                    </div>
                    <div class="menu-backdrop"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>