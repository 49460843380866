<com-header></com-header>
<div class="main">
  <router-outlet></router-outlet>
    <div>
  
    </div>
    <div class="overlay"></div>
    <div class="spanner">
      <div class="loader"></div>
    
    </div>
    <!-- <div *ngIf="showGif" class="gifimg">   
      <img class="gif_img" src="assets/img/logo/app_logo_gif.gif" alt="Loading GIF">
    </div> -->

      <!-- <div *ngIf="showGif" class="gifimg1"> -->
        <!-- <img *ngIf="showGif" class="gif_img1" src="assets/img/Videos/PexcelOnload1.gif" alt="Loading GIF"> -->
        <!-- src/app/body/body.component.html -->
<div id="web-only-div" class="background-container1"  *ngIf="showGif">
  <img src="assets/img/Videos/PexcelOnload1.gif"  alt="Background GIF" class="background-gif1">
</div>
<div id="mobile-only-div" class="Mblbackground-container"  *ngIf="showGif">
  <img src="assets/img/Videos/MobilePexcelOnload.gif"  alt="Background GIF" class="Mblbackground-gif">
</div>

      <!-- </div> -->

</div>

<com-footer></com-footer>
