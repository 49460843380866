export const environment = {
  production: false,
   serviceUrl: 'https://prod-pexcelwebservice-api.azurewebsites.net/api',
  bloburl: "https://pexcelassetstorage.blob.core.windows.net/",
 
  //  serviceUrl: 'https://ottappfunctions.azurewebsites.net/api',
  // bloburl: "https://ottappstorage.blob.core.windows.net/",

   //serviceUrl: 'https://ottappfunctionsdev.azurewebsites.net/api',
  // serviceUrl: 'http://localhost:8070/api',
   Encryptionkey:"tVN0p+0LnGLy8U33RDZ+lg==",
};
