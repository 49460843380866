import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { MyFunctionsService } from 'src/services/functions';
import { WebservicesService } from 'src/services/webapis';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/app/spinner.service';

declare var $;
declare var WOW;
declare var sal;

@Component({
	selector: 'com-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	public serviceUrl = environment.serviceUrl;
	private socket$: WebSocketSubject<any>;
	websocket: WebSocket | null = null;
	SearchForm: FormGroup;
	searchSubmit: boolean;
	GetData: any;
	isToggled = false;
	serachkey: any;
	UserData: any = [];
	tokenname: any;
	result: any;
	User_data: any = [];
	Fname: any;
	Lname: any;
	FullName: string="";
	FirstName:any;
	token: any;
	newMessage = '';
	notification = { messages: [] };
	logs: string[] = [];
	loggedin = false;
	connected = false;
	counter = 0;
	Pushnotification: any;
	NotiticationDescription: any;
	NotificationTitle: any;
	NotificationImage="assets/img/logo/PexcelLogo.png";
	IsSubscribed:boolean=false;
	deviceInfo:DeviceInfo;
	deviceLocation : any;
	IsDeviceLimitExceed:any;
 	formdata:any;
	 currentRoute:any;
	 isLoading: boolean = false;
	 showSpinner : boolean;
	 AgeDataOn:any;
	 AgeDataOff:any;
	 deviceinfo:any;
	 showProgressBar:boolean=false;
	 isMenuOpen =false;
	 showPopup = true;

	constructor(
		private httpClient: HttpClient,
		public API: WebservicesService,
		public CF: MyFunctionsService,
		private fb: FormBuilder,
		private router: Router,
		private activatedRoute: ActivatedRoute, 
		private deviceService: DeviceDetectorService,
		private elementRef:ElementRef,
		private SpinnerService: SpinnerService
		
	) {
	
		//this.deviceinfo = this.activatedRoute;
		this.API.GetGeoIp().then(r => {
			
			this.deviceInfo= deviceService.getDeviceInfo();
			this.deviceLocation = r;
			const token = localStorage.getItem('accessToken');
			if(token && this.deviceInfo)
					this.CheckDeviceStatus();

		})
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
			  this.currentRoute = this.router.url; // Get the current route as a string
			}
		  });
		  

	}
	
	 ngOnInit(): void {
		this.showSpinner = true
		this.isToggled = true;
		this.API.ProgressBarSubject.subscribe((value:any)=>{
			this.showProgressBar= value
	   });
	   

		if(localStorage.getItem('accessToken'))
		   this.tokenname = localStorage.getItem('accessToken');
		
		this.SearchForm = this.fb.group({
			serach: [''],
		})
		setTimeout(() => {
			this.bindfunctions();
			this.top_up();
		}, 50);
		this.UserLogin();
       // this.API.setData(this.GetData);
		this.logintoAzureWebpubsub();
		
	}
	closeDialog(): void {
		this.showPopup = false;
	  }
	closeMobileMenu() 
	{
		
		this.isMenuOpen =true;
		

	 } 
	//  closeMenu() {
	// 	
	// 	// Implement logic to close the mobile menu
	// 	const mobileMenu = document.getElementById('MobileMenu');
	// 	if (mobileMenu) {
	// 	  mobileMenu.classList.remove('open'); // Adjust class name as per your CSS
	// 	}
	//   }
	  closeMenuOnNavigation() {
		// Close the menu when navigation occurs
		$('body').removeClass('mobile-menu-visible');

		//this.closeMenu();
	  }
//  
	// MobileMenu(){
	// 	const mobileMenu = document.getElementById('MobileMenu');
	// 	if (mobileMenu) {
	// 	  mobileMenu.style.display = 'none';  // or you can use other methods to hide it, like adding a class
	// 	}
	// }
	logintoAzureWebpubsub(){
			
			this.API.logintoAzureWeb().then(resp => {
				if (resp != null) {
					
					const url = resp.url;
					const websocket = this.websocket = new WebSocket(url);
			
					websocket.onopen = e => {
					  this.connected = true;
					  this.log(websocket.protocol);
					  this.log('Client websocket opened.');
					};
			
					websocket.onclose = e => {
					  this.connected = false;
					  this.log('Client websocket closed.');
					};
			
					websocket.onerror = e => {
					  this.log('Client websocket error, check the Console window for details.');
					};
			
					websocket.onmessage = e => {
					  if (!e.data) return;
					  this.addMessageToView(e.data);
					  const notificationDetails = JSON.parse(e.data);
					  const myToast = document.getElementById('liveToast');
					  this.NotiticationDescription = notificationDetails.Description;
					  this.NotificationTitle = notificationDetails.Title,
					  this.NotificationImage = notificationDetails.Image;
					  this.showToast();
					};
					
				}
			})

	};
	showToast() {
		const toastElement = document.getElementById('liveToast');
		toastElement.classList.remove('hide');
		toastElement.classList.add('show');
	  }
	  
	  CloseNotification() {
		const toastElement = document.getElementById('liveToast');
		toastElement.classList.remove('show');
		toastElement.classList.add('hide');
	  }
	sendNewMessage() {
		if (!this.newMessage) {
		  this.log('Error: empty message');
		  return;
		}
		//this.sendToServer(this.newMessage);
	
		this.newMessage = '';
	  }
	
	  addItem(item: string, owner: string[]) {
		const id = this.counter++; // vue transitions need an id
		owner.push(item);
	  }
	
	  addMessageToView(message: string) {
		
		this.addItem(message, this.notification.messages);
	
		const elem = document.getElementById('notification');
		if (elem) {
		  elem.scrollTop = elem.scrollHeight;
		}
	  }
	
	  log(content: string) {
		this.addItem(new Date().toLocaleString() + ': ' + content, this.logs);
	  }
	bindfunctions() {
		/*=============================================
		=    		Mobile Menu			      =
	=============================================*/
		//SubMenu Dropdown Toggle

		
		if ($('.menu-area li.menu-item-has-children ul').length) {
			$('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
		}
		//Mobile Nav Hide Show
		if ($('.mobile-menu').length) {

			var mobileMenuContent = $('.menu-area .main-menu').html();
			$('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

			//Dropdown Button
			$('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
				$(this).toggleClass('open');
				$(this).prev('ul').slideToggle(500);
			});
			//Menu Toggle Btn
			$('.mobile-nav-toggler').on('click', function () {
				$('body').addClass('mobile-menu-visible');
			});

			//Menu Toggle Btn
			$('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
				$('body').removeClass('mobile-menu-visible');
			});
		}

	}
	top_up() {
		/*=============================================
		=     Menu sticky & Scroll to top      =
	=============================================*/
		$(window).on('scroll', function () {
			var scroll = $(window).scrollTop();
			if (scroll < 245) {
				$("#sticky-header").removeClass("sticky-menu");
				$('.scroll-to-target').removeClass('open');

			} else {
				$("#sticky-header").addClass("sticky-menu");
				$('.scroll-to-target').addClass('open');
			}
		});


		/*=============================================
			=    		 Scroll Up  	         =
		=============================================*/
		if ($('.scroll-to-target').length) {
			$(".scroll-to-target").on('click', function () {
				var target = $(this).attr('data-target');
				// animate
				$('html, body').animate({
					scrollTop: $(target).offset().top
				}, 2000);
			});
		}

	}

	// ------------------------ Age Toggle ------------------

	clickEvent(event) {
		const switchElement = document.getElementById('toggleageswicth');
		if (this.API.isAgeToggled=="7-11") {
			this.API.setToggle("12-16");
			this.API.isToggled= false;
			  this.RefreshGenereVideos("12-16");
		} else {
			this.API.setToggle("7-11");
			this.API.isToggled= true;
			this.RefreshGenereVideos("7-11");
		}
	}
	clickEvent1(event) {
		const switchElement = document.getElementById('toggleageswicth1');
		if (this.API.isAgeToggled=="7-11") {
			this.API.setToggle("12-16");
			this.API.isToggled= false;
			  this.RefreshGenereVideos("12-16");
		} else {
			this.API.setToggle("7-11");
			this.API.isToggled= true;
			this.RefreshGenereVideos("7-11");
		}
	}
	 toggleSearch() {
		(document.getElementById('searchInput') as HTMLInputElement).value = '';
		var searchcontainer = document.getElementById("search-container");
		if (searchcontainer.style.display === "none") {
			searchcontainer.style.display = "block";
		} else {
			searchcontainer.style.display = "none";
		}
	}
	
	searchtext() {
		
		this.searchSubmit = true;
		if (!this.SearchForm.invalid) {
			const data = this.SearchForm.value;
			this.serachkey = data
			// if (this.API.isToggled) {
			// 	var body = {
			// 	  "AgeGroup" : "12-16",
			// 	  "SearchByText": data.serach
			// 	}
			// 	this.RefreshGenereVideos("12-16");
			//   }
			//   else{
			// 	var body = {
			// 	  "AgeGroup" : "7-11",
			// 		"SearchByText": data.serach
			// 	}
				
			//   }
			  this.RefreshGenereVideos("7-11");
			    this.searchSubmit = false;
		} else {
		}
	}
	mblsearchtext() {
		

		this.searchSubmit = true;
		if (!this.SearchForm.invalid) {
			const data = this.SearchForm.value;
			this.serachkey = data
			  this.RefreshGenereVideos("7-11");
			    this.searchSubmit = false;
		} else {
		}
		this.toggleSearch();
	}


	clicksignIn(e) {
	let currentUrl = window.location.pathname;
    if (!currentUrl.startsWith('/')) {
        currentUrl = '/' + currentUrl;
    }
    let urlSegments = currentUrl.split('/');
    let lastSegment = '/' + urlSegments[urlSegments.length - 1];
 
    localStorage.setItem("CurrentScreen",lastSegment)
		this.API.Login().then(r => {
			if (r != null) {
				window.location.href = r.Value;
			}
		})
	}
	
	clicksignIn1(e) {
		let currentUrl = window.location.pathname;
    if (!currentUrl.startsWith('/')) {
        currentUrl = '/' + currentUrl;
    }
    let urlSegments = currentUrl.split('/');
    let lastSegment = '/' + urlSegments[urlSegments.length - 1];
 
    localStorage.setItem("CurrentScreen",lastSegment)
		$('body').removeClass('mobile-menu-visible');
		this.API.Login().then(r => {
			if (r != null) {
				window.location.href = r.Value;
			}
		})
	}


	
	  async UserLogin() {
		
		const urlFragment = window.location.hash;
		const urlParams = new URLSearchParams(window.location.hash);
		const token = urlParams.get('id_token');
		const code = urlFragment?.split('=')[1];
		const ReplacedCode = code?code.replace('&id_token', ''):"";
		var gettoken = localStorage.getItem('accessToken');
		if(gettoken && token)
		{
			
			var CurrentDeviceId = localStorage.getItem("CurrentDeviceId");
			if(CurrentDeviceId){
				const headers = new HttpHeaders({
					Authorization: 'bearer ' + token
				   });
				   let body={};
				   const url = `${this.serviceUrl}/UserDeviceInfo?DeviceId=${CurrentDeviceId}`;
				   var res= await this.httpClient.put<any>(url,body,{headers}).toPromise();
				  // if(res.Value)
						//localStorage.setItem("CurrentDeviceId",null);
			}
		}
		const errorCode = "AADB2C90037";
		const errorDescription = urlParams.get('error_description');
		const newUrl = window.location.href.split('#')[0];
		window.history.replaceState({}, document.title, newUrl)
		if (token) {
			this.token = token;
			localStorage.setItem('accessToken', token);
			localStorage.setItem('code', ReplacedCode);
			this.SpinnerService.setIsLoggedIn(true);
			this.API.GeTUserRoles().then(r => {
				
				if (r.Value.rolemapped===false) {
					localStorage.removeItem('accessToken');
					this.API.isToggled = true;
					// setTimeout(() => {
					// 	this.API.setGif(false);
					// 	;
					//   }, 2000);
						this.API.setGif(false);

					return this.CF.SwalError('Unauthorized. Please login with valid user credentials.');
				} else if (r.Value.rolemapped===true) {
					this.tokenname = localStorage.getItem('accessToken');
					if (!r.Value.IsDeviceLimitExceed) {
						this.IsDeviceLimitExceed = localStorage.setItem("IsDeviceLimitExceed", "false");
						 this.Userprofiledata();
					} else {
						this.IsDeviceLimitExceed = localStorage.setItem("IsDeviceLimitExceed", "true");
						this.Userprofiledata();
					}
				}
				
			})
		}
		else if(errorDescription && errorDescription.includes(errorCode)){
			this.ForgetPassword();
		}
		else if(errorDescription && errorDescription.includes("AADB2C90091")){
			this.clicksignIn('');
		}
		else if(gettoken){
		// 	this.API.setGif(true);
		// setTimeout(() => {
		// 					this.API.setGif(false);
							
		// 				  }, 7000);
		// 				  this.SpinnerService.setIsLoggedIn(true);
			this.Userprofiledata();
		}
		else{
			
			// setTimeout(() => {
			// 	this.API.setGif(false);
				

			//   }, 2000);
			this.currentRoute = window.location.pathname;

		if (this.currentRoute == '/privacy-policy') 
			this.router.navigateByUrl("/privacy-policy");
		else if (this.currentRoute == '/terms-conditions') 
			this.router.navigateByUrl("/terms-conditions");
		else if (this.currentRoute == '/refunds') 
			this.router.navigateByUrl("/refunds");
		else if (this.currentRoute == '/about') 
			this.router.navigateByUrl("/about");
		else
			this.router.navigateByUrl("/on-boarding");
		// 	this.API.setGif(true);
		// setTimeout(() => {
		// 					this.API.setGif(false);
		// 				  }, 7000);

			  
					
					
			
		}
	}
	ForgetPassword(){
		// setTimeout(() => {
		// 	this.API.setGif(false);
		//   }, 2000);
		this.API.setGif(false);
		
		let body = { }
		this.API.ForgetPassword(body).then(r =>{
			if (r != null) {
				(window.location.href = r.Value)
				.then(() => {
					window.location.reload();
				});
			}
		})
	}
	CheckDeviceStatus(){
		
		var CurrentDeviceId = localStorage.getItem("CurrentDeviceId");

		if(CurrentDeviceId==null || CurrentDeviceId=="null" || CurrentDeviceId=="undefined" || CurrentDeviceId=="" || CurrentDeviceId == "[object Object]"){
			this.formdata = new FormData();
			this.formdata.append('Browser', this.deviceInfo.browser);
			this.formdata.append('OperatingSystem', this.deviceInfo.os);
			this.formdata.append('Location', this.deviceLocation.city + "-" +  this.deviceLocation.region);
			 this.API.CheckStatusAndAddDeviceInfo(this.formdata).then(device => {
				
				if(device.Value!=null)
					localStorage.setItem("CurrentDeviceId",device.Value);
				
			});
     	
		}
		else{
      	this.API.GetDeviceStatus(CurrentDeviceId).then(r => {
          if(!r.Value){
			this.formdata = new FormData();
			this.formdata.append('Browser', this.deviceInfo.browser);
			this.formdata.append('OperatingSystem', this.deviceInfo.os);
			this.formdata.append('Location', this.deviceLocation.city + "-" +  this.deviceLocation.region);
			 this.API.CheckStatusAndAddDeviceInfo(this.formdata).then(device => {
				
				if(device.Value!=null)
					localStorage.setItem("CurrentDeviceId",device.Value);
				});

         	 }
			 
			 
     	 })	
		}


	}
	async AllGenereVideos(age){
	
		const Token = localStorage.getItem('accessToken');
		const data = this.SearchForm.value;
		let body = {
			"AgeGroup": age,
			"SearchByText": data.serach,
		}
        const url = `${this.serviceUrl}/Discover`;
        const resp = await this.httpClient.post<any>(url, body).toPromise();
				this.GetData = resp.Value;
				if( resp.Value &&  resp.Value.length>0)
				localStorage.setItem('Refreshvideos', JSON.stringify(resp.Value))
				this.API.setData(this.GetData);
				$(".spanner").removeClass("show");
				$(".overlay").removeClass("show");
				document.body.style.overflow = 'auto';
    
	}
	async RefreshGenereVideos(age){
		
		$(".spanner").addClass("show");
		$(".overlay").addClass("show");
		document.body.style.overflow = 'hidden';
		const data = this.SearchForm.value;
		let body = {
			"AgeGroup": age,
			"SearchByText": data.serach,
			"pageIndex": 0
		}
		const Token = localStorage.getItem('accessToken');
		const GenreVideos = JSON.parse(localStorage.getItem('GenreDetails'));
		if (this.currentRoute === '/home' || this.currentRoute === '/' || this.currentRoute.includes('/home') ) {
			if(!Token || (Token &&  this.UserData?.SubscriptionType == 'No Active Plan')){
				//this.API.GetDemoVideos().subscribe((r :any) =>{
					//this.API.setDemoData(r.Value);
					//$(".spanner").removeClass("show");
				//$(".overlay").removeClass("show");
				//document.body.style.overflow = 'auto';
				//})	
		   }
		   await this.AllGenereVideos(age);
		}
		else if (this.currentRoute === '/watch-history') {
				this.API.sethistory([]);
				$(".spanner").removeClass("show");
				$(".overlay").removeClass("show");
				document.body.style.overflow = 'auto';
		}
		else if (this.currentRoute === '/Particular_genre') {
			  if(GenreVideos.length==1){
				var body1 = {
					"AgeGroup": age,
					"SearchByText": data.serach,
					"Genres" : [GenreVideos[0].GenreName]
				  }
				this.Fname =  GenreVideos[0].GenreName ;
				await this.API.GetAgeGroupVideos(body1).then((response: any) => {
				   if(response.Value.length>0){
					   localStorage.setItem('GenreDetails', JSON.stringify(response.Value))
					  this.API.setSpecificData(response.Value);
				  }
				 else{
					GenreVideos[0].Videos=[];
					GenreVideos[0].listSubGenresDto=[];
					   localStorage.setItem('GenreDetails', JSON.stringify(GenreVideos))
					   this.API.setSpecificData(GenreVideos);
				 }
			   })
			   $(".spanner").removeClass("show");
			   $(".overlay").removeClass("show");
			   document.body.style.overflow = 'auto';
			}
		   else if(GenreVideos?.lstVideoDto.length>0  || GenreVideos?.lstVideoDto.length==0){
			   var body2 = {
				   "AgeGroup": age,
				   "SearchByText": data.serach,
				   "Genres" : [GenreVideos.Name]
				 }
				 this.API.GetAgeGroupVideosOfSubGenres(body2).then((r: any) => {
				   //this.GetData = r.Value;
				   localStorage.setItem('GenreDetails', JSON.stringify(r.Value))
				   this.API.setSpecificData(r.Value);
				 })
				 $(".spanner").removeClass("show");
				 $(".overlay").removeClass("show");
				 document.body.style.overflow = 'auto';
			}
			else{
			   this.API.setSpecificData([]);
			   $(".spanner").removeClass("show");
			   $(".overlay").removeClass("show");
			   document.body.style.overflow = 'auto';
			}
		} 
		else{
			$(".spanner").removeClass("show");
				$(".overlay").removeClass("show");
				document.body.style.overflow = 'auto';
		}
		
	}
	
	GetAgeGroupVideoss(age) {
		
		this.currentRoute = this.activatedRoute.root.firstChild?.snapshot.routeConfig?.path;

		if (this.currentRoute != '/play_video') {
		const data = this.SearchForm.value;
		let body = {
			"AgeGroup": age,
			"SearchByText": data.serach,
			"pageIndex": 0
			//   "SearchByText": this.serachkey.serach
		}
			this.API.GetAgeGroupVideos(body).then((r: any) => {
				
				this.GetData = r.Value;
			//	localStorage.setItem('GenreDetails', JSON.stringify(r.Value))
				this.API.setData(this.GetData);
				// if (this.currentRoute === '/Particular_genre') 
				// window.location.reload();
			})
		
		}
	}
	
	// ------------------------ Age Toggle ------------------

	async Userprofiledata() {
		
		await this.API.GeTProfileDetails().subscribe(r => {
			
			try {
				

				if (r) {
					
					this.UserData = r.Value;
					var userData = JSON.stringify(r.Value);
					localStorage.setItem("UserData", userData);
					this.API.setToggle(this.UserData.AgeGroup);
					this.FullName = this.UserData.Name;
					this.FirstName = this.UserData.FirstName;
					this.IsSubscribed = r.Value.SubscriptionType != "No Active Plan" ? true : false;
					localStorage.setItem("IsSubscribed", r.Value.SubscriptionType)
					if(this.UserData.AgeGroup == "12-16"){
						this.isToggled = false;
						this.API.isToggled = false;
						const switchElement = document.getElementById('toggleageswicth');
						const switchElement1 = document.getElementById('toggleageswicth1');

						if (switchElement) {
						  switchElement.dataset.on = "7-11 Yr";
						  switchElement.dataset.off = "12-16 Yr";
						} 
						if (switchElement1) {
							switchElement1.dataset.on = "7-11 Yr";
							switchElement1.dataset.off = "12-16 Yr";
						  } 
						//   setTimeout(() => {
							
						// 	this.API.setGif(false);
						//   }, 2000);
						this.API.setGif(false);

					}
					else{
						this.isToggled = true;
						this.API.isToggled = true;
						const switchElement = document.getElementById('toggleageswicth');
						const switchElement1 = document.getElementById('toggleageswicth1');

						if (switchElement) {
						  switchElement.dataset.off = "7-11 Yr";
						  switchElement.dataset.on = "12-16 Yr";
						} 
						if (switchElement1) {
							switchElement.dataset.off = "7-11 Yr";
							switchElement.dataset.on = "12-16 Yr";
						  } 
						//   setTimeout(() => {
							
						// 	this.API.setGif(false);
						//   }, 2000);
						this.API.setGif(false);

					}
					
					// var CurrentScreen= localStorage.getItem("CurrentScreen")

					// if(CurrentScreen){
					// 	localStorage.removeItem("CurrentScreen")
					// 	this.router.navigateByUrl(CurrentScreen);
						
    
					// }
					//this.RefreshGenereVideos(this.UserData.AgeGroup);
					//if (localStorage.getItem("IsDeviceLimitExceed") == "true")
						//this.router.navigateByUrl(`/device-detail`);
					//else
						
					
				} 
				else {
					this.logout();
					// setTimeout(() => {
					// 	this.API.setGif(false);
					//   }, 2000);
					this.API.setGif(false);

				}
			} catch (error) {
				
				console.error('An error occurred:', error);
				// setTimeout(() => {
				// 	this.API.setGif(false);
				//   }, 2000);
				this.logout();
				this.API.setGif(false);

			}
		});
	}
	

	logout() {
		
		 this.SpinnerService.setIsLoggedIn(true);
		
		// this.API.setGif(true);
						
		this.API.setprogressbar(true);
		this.API.Logout().then(r => {
			this.API.setGif(true);
			if(r.Value){
				localStorage.removeItem('accessToken');
				localStorage.clear();
				this.tokenname=false;
				this.router.navigateByUrl("/on-boarding")
					.then(() => {
						window.location.reload();
				});
				// this.RefreshGenereVideos("7-11");
				// this.router.navigate([`/home`]) 
				// .then(() => {
				// 		window.location.reload();
				// });
			}
			this.API.setprogressbar(false);
		})
	}
}
